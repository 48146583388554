import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VendorDataService {

  constructor(private http: HttpClient) { }




  getVendorData() {
    return this.http.get('vendors/');
  }

  postVendorData(payload: any) {
    return this.http.post('vendors/', payload);
  }
  getVendorLoc(id: number) {
    return this.http.get(`vendors/${id}/locations`);
  }


  updateVendorData(payload: any, id: any) {
    //return this.http.put(`vendor_rate/update/${id}/', payload);
    return this.http.put(`vendors/${id}/`, payload);

  }

  getVendorLocation() {
    return this.http.get('vendors/locations/');
  }


  updateMarketData(payload: any, id: any) {
    //return this.http.put(`vendor_rate/update/${id}/', payload);
    return this.http.put(`market-rates/${id}/`, payload);

  }

  
}
