<div class="w-100 bg-lavendar mt-80">
    <app-header></app-header>
</div>
<div class="container-fluid container-xxl">
    <div class="row w-100  m-0">
        <div class="col-md-auto p-0 section-2 mt-4">
            <!-- <app-left-bar></app-left-bar> -->
        </div>
        <div class="row section-3 px-0 mt-4">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>