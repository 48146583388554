import { Component, OnInit, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Highcharts, { Options } from "highcharts/highmaps";
import worldMap from "@highcharts/map-collection/custom/world.geo.json";
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProgramService } from 'src/app/shared/services/program.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { RateService } from 'src/app/shared/services/rate.service';
import { DateAdapter } from '@angular/material/core';
import { ProjectService } from 'src/app/shared/services/project.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActionTrackerService } from 'src/app/shared/services/action-tracker.service';
import { VendorDataService } from 'src/app/shared/services/vendor-data.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
  providers: [DatePipe]
})
export class AddComponent implements OnInit {
  dataSource !: MatTableDataSource<any>;
  editData: any;
  showForm: boolean = false;
  showLocation: boolean = false;
  vendorList: any;
  vendorListNew: any;
  dataSource1: any[] = [];

  patchResponse: any;
  newVendorItems: any;
  selectedVendorItems: any[] = [];
  selectedVendorItemsInitial: any[] = [];

  // staticText: any = (textConfiguration as any).default;
  projectId: any;
  projectdetail: any;
  opportunity_data: any;
  ownerdetails: any;
  details: any;
  opportunityFilteredOptions: any;
  vendorFilteredOptions: any;
  projectForm: FormGroup | any;
  myForm: FormGroup | any;
  getData: any;
  gdpList: any;
  vendorDetail: any;
  vendorId: any;
  selected: any;
  my_object: any;
  updateId: any;
  selectedOpportunity: any = '';
  statusList: any = [];
  /* @Input() selected_id: any; */
  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = "mapChart";
  chartData = [{ code3: "ABW", z: 105 }, { code3: "AFG", z: 35530 }];
  disabled: boolean = true;
  @ViewChild(MatPaginator) paginator !: MatPaginator;
  @ViewChild(MatSort) sort !: MatSort;
  constructor(private projectservice: ProjectService,
    private actionTrackerService: ActionTrackerService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private program: ProgramService,
    private fb: FormBuilder,
    private date: DatePipe,
    private toastr: ToastrService,
    private vendorData: VendorDataService,
    private ps: ProgramService,
    private matDialog: MatDialog,
    private cd: ChangeDetectorRef,
    private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB')
  }

  ngOnInit(): void {
    this.getvendorData();
  }
  editVendor(row: any) {
    console.log('row', row);
    this.matDialog.open(DialogComponent, {
      // data: {
      //   animal: 'panda',
      // },
      width: '30%',
      data: row
    }).afterClosed().subscribe(val => {
      if (val == 'update') {
        this.getvendorData();
      }
    })
  }
  add() {
    this.matDialog.open(DialogComponent, {
      // data: {
      //   animal: 'panda',
      // },
      width: '30%'
    }).afterClosed().subscribe(val => {
      if (val == 'save') {
        this.getvendorData();
      }
    })

  }
  getvendorData() {
    this.spinner.show();
    this.vendorData.getVendorData().subscribe((res: any) => {
      this.getData = res;
      console.log('vendordata -', this.getData)
      this.dataSource = new MatTableDataSource(res);
      /***hardcoded for now*/
      this.dataSource1 = res;
      if (this.dataSource1.length > 4) {
        this.dataSource1.length = 4
      };
      console.log('dataSource list -', this.dataSource);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;


      this.spinner.hide();
    })
  }

  

}
