import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { NgxSpinnerService } from 'ngx-spinner';
import { VendorDataService } from 'src/app/shared/services/vendor-data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  vendorForm !: FormGroup;
  postData: any;
  vendorLoc: any;
  actionBtnUpdate: boolean = false;
  actionBtnAdd: boolean = false;

  myFiles: string[] = [];
  fileList: any[] = [];
  files: any[] = [];
  file !: File;
  imageSrc: string = '';
  selected: any;
  constructor(private formbuilder: FormBuilder,
    private toastr: ToastrService,

    private spinner: NgxSpinnerService,
    private vendorData: VendorDataService,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public editData: any,
    private dialogRef: MatDialogRef<DialogComponent>) { }

  ngOnInit(): void {
    this.actionBtnAdd = true;

    this.getvendorDatLoc();
    this.vendorForm = this.formbuilder.group({
      vendorName: [''],
      vendorType: ['', Validators.required],
      vendorEmail: ['', Validators.required],
      vendorWebsite: ['', Validators.required],
      vendorlocation: ['', Validators.required],
      contract: ['', Validators.required],
      team: ['', Validators.required],
      logo: ['', Validators.required]
    });
    console.log('editData', this.editData);

    if (this.editData) {
      this.actionBtnUpdate = true;
      this.actionBtnAdd = false;
      this.vendorForm.controls['vendorName'].setValue(this.editData.vendor_name);
      this.vendorForm.controls['vendorType'].setValue(this.editData.vendor_type);
      this.vendorForm.controls['vendorEmail'].setValue(this.editData.vendor_contact_mail);
      this.vendorForm.controls['vendorWebsite'].setValue(this.editData.website);
      this.vendorForm.controls['vendorlocation'].setValue(this.editData.vendor_locations);
      this.vendorForm.controls['contract'].setValue(this.editData.contract_ref);
      this.vendorForm.controls['team'].setValue(this.editData.team);
      this.vendorForm.controls['logo'].setValue(this.editData.logo);

    }

  }
  updateVendor() {
    this.spinner.show();

    console.log(this.editData.id)
    var formData: any = new FormData();
    formData.append('vendor_name', this.vendorForm.value.vendorName);
    formData.append('vendor_type', this.vendorForm.value.vendorType);
    formData.append('vendor_contact', this.vendorForm.value.contract);
    formData.append('contract_ref', this.vendorForm.value.contract);
    formData.append('website', this.vendorForm.value.vendorWebsite);
    formData.append('logo', this.file);
    formData.append('vendor_locations', this.vendorForm.value.vendorlocation);
    formData.append('vendor_contact_mail', this.vendorForm.value.vendorEmail);
    this.vendorData.updateVendorData(formData, this.editData.id)
      .subscribe({
        next: (res) => {
          console.log(res);
          this.toastr.success("Vendor updated successfully");
          //this.vendorForm.reset();
          this.dialogRef.close('update');
          this.spinner.hide();

        },
        error: () => {
          alert('error while upadting')
          this.spinner.hide();

        }
      })
  }
  getvendorDatLoc() {
    this.spinner.show();
    this.vendorData.getVendorLocation().subscribe((res: any) => {
      this.vendorLoc = res;
      console.log('vendorLoc', this.vendorLoc)

      this.selected = this.vendorLoc[364].vendor_locations.Offshore.countryCode;
      console.log('selected', this.selected)


      this.spinner.hide();
    })
  }

  addVendor() {
    this.spinner.show();

    console.log('file', this.file)
    if (!this.editData) {
      if (this.vendorForm.valid) {
        var formData: any = new FormData();
        formData.append('vendor_name', this.vendorForm.value.vendorName);
        formData.append('vendor_type', this.vendorForm.value.vendorType);
        formData.append('vendor_contact', this.vendorForm.value.contract);
        formData.append('contract_ref', this.vendorForm.value.contract);
        formData.append('website', this.vendorForm.value.vendorWebsite);
        formData.append('logo', this.file);
        formData.append('vendor_locations', this.vendorForm.value.vendorlocation);
        formData.append('vendor_contact_mail', this.vendorForm.value.vendorEmail);

        // let payload = {
        //   "vendor_name": this.vendorForm.value.vendorName,
        //   "vendor_type": this.vendorForm.value.vendorType,
        //   "vendor_contact": this.vendorForm.value.contract,
        //   "contract_ref": this.vendorForm.value.contract,
        //   "website": this.vendorForm.value.website,
        //   "logo": this.imageSrc,
        //   "vendor_locations": this.vendorForm.value.location,
        //   "vendor_contact_mail": this.vendorForm.value.vendorEmail,
        // }

        this.vendorData.postVendorData(formData)
          .subscribe({
            next: (res) => {
              this.postData = res;
              console.log(this.postData);
              this.toastr.success("Vendor added successfully");
              this.vendorForm.reset();
              this.dialogRef.close('save');
              this.spinner.hide();

            },
            error: () => {
              alert('error')
              this.spinner.hide();

            }

          })
      }


    }
    else {

      //this.updateProduct();
    }

    console.log(this.vendorForm.value)
  }
  onFileSelected(e: any) {
    for (var i = 0; i < e.target.files.length; i++) {
      this.files.push(e.target.files[i]);
      this.fileList.push(e.target.files[i].name,);
    }
  }

  onFileChange(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const fd: any = new FormData();
      // fd.append('file_name', event.target.files[0].name);
      // fd.append('logo', event.target.files[0]);
      const [file] = event.target.files;
      this.file = event.target.files[0];
      console.log('file', this.file)
      this.imageSrc = file;
      console.log('imageSrc', this.imageSrc)


      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imageSrc = reader.result as string;
        console.log('imageSrcNew', this.imageSrc);


        // this.vendorForm.patchValue({
        //   logo: reader.result
        // });
        console.log('logo', reader.result)


      };

      this.cd.markForCheck();

    }
  }

  // onFileSelected(e: any, option: any, i: any) {
  //   const file = e;
  //   const fd: any = new FormData();
  //   fd.append('file_name', file.target.files[0].name);
  //   fd.append('file', file.target.files[0]);
  //   fd.append('section_id', option.id);
  //   if (file.target.files[0].size > 5000000) {
  //     this.toastrService.error('Please upload less than 5MB');
  //     return;
  //   }
  //   this.spinner.show();
  //   this.moreInfoService.uploadFile(fd, this.opportunityId).subscribe((res: any) => {
  //     this.toastrService.success('File uploaded successfully');
  //     const obj = { file: file.target.files[0].name, section: option.id, doc: String, triggerDelete: false};
  //     this.fileArray.push(obj);
  //     const fileName = file.target.files[0].name;
  //     const ext = fileName.substring(fileName.lastIndexOf('.') + 1)
  //     const newObj = { file: file.target.files[0].name, section: option.id, date: moment().format('ll'), doc: ext?.toLowerCase() };
  //     this.newFileArray.push(newObj);
  //     this.spinner.hide();
  //     this.checkIsFileUploaded(option);
  //   }, error => {
  //     this.spinner.hide();
  //     throw error;
  //   });
  // }

  // downloadFile(e: any) {
  //   var payload = {
  //     file_name: e.file,
  //     section_id: e.section
  //   }
  //   this.spinner.show();
  //   this.moreInfoService.downloadFile(payload, this.opportunityId).subscribe((res: any) => {
  //     let downloadLink = document.createElement('a');
  //     downloadLink.href = window.URL.createObjectURL(res);
  //     downloadLink.setAttribute('download', e.file);
  //     document.body.appendChild(downloadLink);
  //     downloadLink.click();
  //     this.toastrService.success('File downloaded successfully');
  //     this.spinner.hide();
  //   }, error => {
  //     this.spinner.hide();
  //     throw error;
  //   });
  // }
}
