<div class="container-fluid container-xxl program">
    <div class="custom-height" id="custom-height">
        <div class="row">
            <div class="col-md-12">
                <div class="screen-style">
                    <div class="row mb-4">
                        <div class="col-md-7">
                            <div class="pt-4 ">
                                <h2 class="screen-name mt-1">Vendor</h2>
                            </div>
                        </div>
                        <div class="col-md-5 pr-1">
                            <div class="d-flex justify-content-end  pt-4">

                                <button (click)="add()"
                                    class="float-end border primary lightSecondary download-btn  ml-3" mat-button
                                    mat-flat-button>
                                    <mat-icon aria-hidden="false" aria-label="material-icons-filled "
                                        class="cursor-pointer fs-5">add
                                    </mat-icon>
                                    Add Vendor
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 p-0 ">
                        <!-- <div class="container-fluid p-0">
                            yyy
                            <app-tabmenu [tabcontentdynamic]="portList" [status]="statusList"
                                (portfolio)="selectedPortfolioValues($event)" showOpportunity="true" (onSelectOpportunity)="onSelectOpportunity($event)"
                                (subportfolioId)="selectedSubportfolioValues($event)"
                                (statusValue)="statusChange($event)" [subportfolio]="subPortfilteredOptions"
                                (clearFilterData)="clearFilter()"></app-tabmenu>
                        </div> -->
                        <table class="table table-striped" style="font-size: 13px;">
                            <thead>
                                <tr>
                                    <th class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                                        scope="col"><span class="d-flex">Vendor Name</span>
                                    </th>
                                    <th class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                                        scope="col"><span class="d-flex">Vendor Type</span>
                                    </th>
                                    <th class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                                        scope="col"><span class="d-flex">Vendor Email</span>
                                    </th>
                                    <th class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                                        scope="col"><span class="d-flex">Website</span>
                                    </th>
                                    <th class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                                        scope="col"><span class="d-flex">Logo</span>
                                    </th>
                                    <th class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                                        scope="col"><span class="d-flex">Locations</span>
                                    </th>
                                    <th class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                                        scope="col"><span class="d-flex">Contract</span>
                                    </th>
                                    <th class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                                        scope="col"><span class="d-flex">Team</span>
                                    </th>
                                    <th class="fs-14 text-capitalize fw-500 text-white table-action-header border-end border-light"
                                        scope="col"><span class="d-flex justify-content-center">Actions</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="cursor-pointer">
                                <tr *ngFor="let tableRecords of dataSource1;index as tableIndex">
                                    <td>
                                        <span class="d-flex ">
                                            {{tableRecords.vendor_name}}
                                        </span>
                                    </td>
                                    <td>
                                        <span class="">
            
                                            <span>{{tableRecords.vendor_type}}</span>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="">
            
                                            <span>{{tableRecords.vendor_contact_mail}}</span>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="">
            
                                            <span>{{tableRecords.website}}</span>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="">
                                            <img [src]="tableRecords.logo" alt="Place image title">
                                            <!-- <span>{{tableRecords.logo}}</span> -->
                                        </span>
                                    </td>
                                    <td>
                                        <span class="">
            
                                            <span>{{tableRecords.vendor_locations}}</span>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="">
            
                                            <span>{{tableRecords.contract_ref}}</span>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="">
            
                                            <span>{{tableRecords.team}}</span>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="">
                                            <button class="d-flex justify-content-center" mat-icon-button matTooltip="Click to Edit"
                                                (click)="editVendor(tableRecords)" class="iconbutton" color="primary">
                                                <mat-icon aria-label="Edit">edit</mat-icon>
                                            </button>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
                    </div>
                    <!-- <div class="col-md-12 p-0">
                        <div class="container-fluid p-0">
                            <div class="d-flex justify-content-between align-items-center pt-4">
                                <div>
                                    <p class="count">xxx{{staticText?.opportunity?.opportunities}} ({{totalCount}})</p>
                                </div>
                                <button mat-button class="float-end border primary lightSecondary download-btn ml-3" *ngIf="opportunity && opportunity?.length>0"
                                data-toggle="tooltip" data-placement="top" title="Export as CSV" (click)="getDataSource(true)" mat-flat-button>xxxDownload</button>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div>
                        <app-table-card [proposalLists]="opportunity" [screen_name]="screen_name"></app-table-card>
                        <div class="d-flex">
                            <div class="col-md-10" *ngIf="!disableShowMore && totalCount > 10"
                                class="show-more-wrapper">
                                <a class="show-more fw-normal" (click)="showAllList()">xxx{{staticText?.common?.show_more}}<i
                                        class="fas fa-angle-double-down fw-normal m-1"></i>
                                </a>
                            </div>
                            
                        </div>

                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <ng-template #delete>
    <div class="modal-dialog custom-width m-0">
        <div class="modal-content">
            <div class="modal-header">
                <h2 mat-dialog-title class="mb-0">xxx{{staticText?.modal_popup?.confirmation_title}}</h2>
            </div>
            <div class="modal-body">
                <p class=" fs-5 py-3 text-center primary m-0 fw-bold">xx{{staticText?.modal_popup?.del_msg}}</p>
            </div>
            <div class="modal-footer border-0">
                <div class="row">
                    <div class="col-md-6">
                        <button mat-button mat-dialog-close (click)="closeDialog()">xxx{{staticText?.modal_popup?.no_btn}}</button>
                    </div>
                    <div class="col-md-6">
                        <button mat-button mat-dialog-close (click)="deleteDialog()"
                            class="bg-primary text-white">xxx{{staticText?.modal_popup?.yes_btn}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template> -->