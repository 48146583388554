import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VendorDataRoutingModule } from './vendor-data-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialmodulesModule } from 'src/app/shared/materialmodules.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import { VendorListNewComponent } from './vendor-list-new/vendor-list-new.component';
import { IndexComponent } from './index/index.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { PipeModule } from 'src/app/shared/pipes/pipe.module';
import { MatRadioModule } from '@angular/material/radio';
import { ChartModule } from 'angular-highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import { AddComponent } from './add/add.component';
import { DialogComponent } from './dialog/dialog.component';
@NgModule({
  declarations: [
    VendorListNewComponent,
    IndexComponent,
    AddComponent,
    DialogComponent
  ],
  imports: [
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    NgxPaginationModule,
    FormsModule, ReactiveFormsModule,
    MatExpansionModule,
    MatListModule,
    MatSidenavModule,
    MatIconModule,
    MatProgressBarModule,
    MatMenuModule,
    MatCardModule,
    MatIconModule,
    PipeModule.forRoot(),
    MatRadioModule,
    ChartModule,
    HighchartsChartModule,
    CommonModule,
    SharedModule,
    NgxPermissionsModule,
    MaterialmodulesModule,
    NgbModule,
    VendorDataRoutingModule,
    PipeModule.forRoot(),
    MatRadioModule,
    ChartModule,
    HighchartsChartModule,
    NgCircleProgressModule.forRoot(/*{
      "radius": 60,
      "space": -10,
      "outerStrokeGradient": true,
      "outerStrokeWidth": 10,
      "outerStrokeColor": "#4882c2",
      "outerStrokeGradientStopColor": "#53a9ff",
      "innerStrokeColor": "#e7e8ea",
      "innerStrokeWidth": 10,
      "title": "UI",
      "animateTitle": false,
      "animationDuration": 1000,
      "showUnits": false,
      "showBackground": false,
      "clockwise": false,
      "startFromZero": false,
      "lazy": true
    }*/)
  ]
})
export class VendorDataModule { }
