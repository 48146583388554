<div class="row w-100 h-100 m-0">


    <div class="col-md-auto center-panel p-0 px-0">


        <div class="edit">
            <div style="text-align: center;">
                <h1 style="font-size: 20px;">Vendor</h1>
            </div>
            <!-- <div style="margin-left: 10px;" class="row">
             
                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="w-100 form-color">
                        <mat-select placeholder="Select Vendor" class="w-100  py-1 px-3 rounded" appearance="filled"
                            formControlName="Vendor"
                            (selectionChange)="vendorChange($event.value)">
                            <mat-option *ngFor="let item of vendorList; let i=index" [value]="item.id">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div style="margin-top: 20px" class="col-md-6">
                    <button style="width: 140px;" (click)="showBtnLocation()">Locations</button>
                </div>

            </div> -->
            <!-- <table mat-table [dataSource]="dataSource" matSort>

              
                <ng-container matColumnDef="job_title">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> JobTitle. </th>
                    <td mat-cell *matCellDef="let element"> {{element.job_title.name}} </td>
                </ng-container>

              
                <ng-container matColumnDef="max_onsite_rate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Onsite </th>
                    <td mat-cell *matCellDef="let element"><span>{{element.max_onsite_rate}}</span> <br>-<br><span>{{element.min_onsite_rate}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="max_offshore_rate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> OffShor </th>
                    <td mat-cell *matCellDef="let element"> <span>{{element.max_offshore_rate}}</span><br>-<br><span>{{element.min_offshore_rate}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="max_domestic_rate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Dom </th>
                    <td mat-cell *matCellDef="let element"> <span>{{element.max_domestic_rate}}</span><br>-<br><span>{{element.min_domestic_rate}}</span> </td>
                </ng-container>
                <ng-container matColumnDef="max_nearshore_rate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> NearShor </th>
                    <td mat-cell *matCellDef="let element"> <span>{{element.max_nearshore_rate}}</span><br>-<br><span>{{element.min_nearshore_rate}}</span> </td>
                </ng-container>
             
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td *matCellDef="let element">
                        <button mat-icon-button matTooltip="Click to Edit" (click)="editForm(element)"
                            class="iconbutton" color="primary">
                            <mat-icon aria-label="Edit">edit</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table> -->
            <table class="table table-striped" style="font-size: 13px;">
                <thead>
                    <tr>
                        <th class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                            scope="col"><span class="d-flex">Vendor Name</span>
                        </th>
                        <th class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                            scope="col"><span class="d-flex">Vendor Type</span>
                        </th>
                        <th class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                            scope="col"><span class="d-flex">Vendor Email</span>
                        </th>
                        <th class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                            scope="col"><span class="d-flex">Website</span>
                        </th>
                        <th class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                            scope="col"><span class="d-flex">Logo</span>
                        </th>
                        <th class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                            scope="col"><span class="d-flex">Locations</span>
                        </th>
                        <th class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                            scope="col"><span class="d-flex">Contract</span>
                        </th>
                        <th class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                            scope="col"><span class="d-flex">Team</span>
                        </th>
                        <th class="fs-14 text-capitalize fw-500 text-white table-action-header border-end border-light"
                            scope="col"><span class="d-flex justify-content-center">Actions</span>
                        </th>
                    </tr>
                </thead>
                <tbody class="cursor-pointer">
                    <tr *ngFor="let tableRecords of dataSource1;index as tableIndex">
                        <td>
                            <span class="d-flex ">
                                {{tableRecords.vendor_name}}
                            </span>
                        </td>
                        <td>
                            <span class="">

                                <span>{{tableRecords.vendor_type}}</span>
                            </span>
                        </td>
                        <td>
                            <span class="">

                                <span>{{tableRecords.vendor_contact_mail}}</span>
                            </span>
                        </td>
                        <td>
                            <span class="">

                                <span>{{tableRecords.website}}</span>
                            </span>
                        </td>
                        <td>
                            <span class="">
                                <img [src]="tableRecords.logo" alt="Place image title">
                                <!-- <span>{{tableRecords.logo}}</span> -->
                            </span>
                        </td>
                        <td>
                            <span class="">

                                <span>{{tableRecords.vendor_locations}}</span>
                            </span>
                        </td>
                        <td>
                            <span class="">

                                <span>{{tableRecords.contract_ref}}</span>
                            </span>
                        </td>
                        <td>
                            <span class="">

                                <span>{{tableRecords.team}}</span>
                            </span>
                        </td>
                        <td>
                            <span class="">
                                <button class="d-flex justify-content-center" mat-icon-button matTooltip="Click to Edit"
                                    (click)="editForm(tableRecords)" class="iconbutton" color="primary">
                                    <mat-icon aria-label="Edit">edit</mat-icon>
                                </button>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
        </div>


    </div>
    <div class="col-md-3 edit" *ngIf="showForm === true">

        <form [formGroup]="myForm">
            <!-- <mat-form-field class="example-full-width">
                <mat-label class="label-design">Name</mat-label> -->
            <!-- <input matInput placeholder="Name" formControlName="name"> -->
            <!-- <input type="text" matInput fullWidth formControlName="name" id='name'>
            </mat-form-field> -->
            <div class="d-flex border-bottom">
                <span class="bubble-right pt-2 pb-2"></span>
                <div class="pe-1 ps-1 w-100">
                    <div class="d-flex justify-content-between align-items-center topBorders">
                        <div class="p-3">
                            <p class="fw-bold fs-18 text-black mb-0">{{editData.job_title.name}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label class="label-design">JobTitle</mat-label>
                    <input [readonly]="isReadOnly" type="text" matInput fullWidth formControlName="JobTitle" id='JobTitle' disabled>
                   
                </mat-form-field>

            </div> -->


            <div class="row">

                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label class="label-design">Onsite Min</mat-label>
                        <input type="text" matInput fullWidth formControlName="OnsiteMin" id='OnsiteMin'>
                        <!-- <mat-error *ngIf="(submitted|| f.name.touched) && f.name.errors">
                        <small class="text-danger"
                            *ngIf="f.name.errors.required">{{staticText?.rfx.preview_section.opp_name}}
                            is
                            required</small>
                    </mat-error> -->
                    </mat-form-field>

                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label class="label-design">Onsite Max</mat-label>
                        <input type="text" matInput fullWidth formControlName="OnsiteMax" id='OnsiteMax'>
                        <!-- <mat-error *ngIf="(submitted|| f.name.touched) && f.name.errors">
                        <small class="text-danger"
                            *ngIf="f.name.errors.required">{{staticText?.rfx.preview_section.opp_name}}
                            is
                            required</small>
                    </mat-error> -->
                    </mat-form-field>

                </div>
            </div>
            <div class="row">

                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label class="label-design">OffShor Min</mat-label>
                        <input type="text" matInput fullWidth formControlName="OffShorMin" id='OffShorMin'>
                        <!-- <mat-error *ngIf="(submitted|| f.name.touched) && f.name.errors">
                        <small class="text-danger"
                            *ngIf="f.name.errors.required">{{staticText?.rfx.preview_section.opp_name}}
                            is
                            required</small>
                    </mat-error> -->
                    </mat-form-field>

                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label class="label-design">OffShor Max</mat-label>
                        <input type="text" matInput fullWidth formControlName="OffShorMax" id='OffShorMax'>
                        <!-- <mat-error *ngIf="(submitted|| f.name.touched) && f.name.errors">
                        <small class="text-danger"
                            *ngIf="f.name.errors.required">{{staticText?.rfx.preview_section.opp_name}}
                            is
                            required</small>
                    </mat-error> -->
                    </mat-form-field>

                </div>
            </div>
            <div class="row">

                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label class="label-design">Domes Min</mat-label>
                        <input type="text" matInput fullWidth formControlName="DomMin" id='DomMin'>
                        <!-- <mat-error *ngIf="(submitted|| f.name.touched) && f.name.errors">
                        <small class="text-danger"
                            *ngIf="f.name.errors.required">{{staticText?.rfx.preview_section.opp_name}}
                            is
                            required</small>
                    </mat-error> -->
                    </mat-form-field>

                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label class="label-design">Domes max</mat-label>
                        <input type="text" matInput fullWidth formControlName="DomMax" id='DomMax'>
                        <!-- <mat-error *ngIf="(submitted|| f.name.touched) && f.name.errors">
                        <small class="text-danger"
                            *ngIf="f.name.errors.required">{{staticText?.rfx.preview_section.opp_name}}
                            is
                            required</small>
                    </mat-error> -->
                    </mat-form-field>

                </div>
            </div>
            <div class="row">

                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label class="label-design">Nearsh Min</mat-label>
                        <input type="text" matInput fullWidth formControlName="NearShorMin" id='NearShorMin'>
                        <!-- <mat-error *ngIf="(submitted|| f.name.touched) && f.name.errors">
                        <small class="text-danger"
                            *ngIf="f.name.errors.required">{{staticText?.rfx.preview_section.opp_name}}
                            is
                            required</small>
                    </mat-error> -->
                    </mat-form-field>

                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label class="label-design">Nearsh Max</mat-label>
                        <input type="text" matInput fullWidth formControlName="NearShorMax" id='NearShorMax'>
                        <!-- <mat-error *ngIf="(submitted|| f.name.touched) && f.name.errors">
                        <small class="text-danger"
                            *ngIf="f.name.errors.required">{{staticText?.rfx.preview_section.opp_name}}
                            is
                            required</small>
                    </mat-error> -->
                    </mat-form-field>

                </div>
            </div>


            <div mat-dialog-action [align]="'end'">
                <button mat-raised-button style="background-color: #1a85d6; margin-right: 10px;"
                    (click)="close()">Cancel</button>
                <button style="margin-left: 8px;" mat-raised-button style="background-color: #1a85d6;"
                    (click)="updateVendorDetails()">Save</button>

            </div>
        </form>
    </div>
    <div *ngIf="showLocation" class="col-md-auto p-0 section-3">
        <highcharts-chart id="container" [Highcharts]="Highcharts" [constructorType]="chartConstructor"
            [options]="chartOptions" style="width: 100%; height: 400px; display: block;">
        </highcharts-chart>

    </div>


</div>