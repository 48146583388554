import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Highcharts, { Options } from "highcharts/highmaps";
import worldMap from "@highcharts/map-collection/custom/world.geo.json";
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProgramService } from 'src/app/shared/services/program.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { RateService } from 'src/app/shared/services/rate.service';
import { DateAdapter } from '@angular/material/core';
import { ProjectService } from 'src/app/shared/services/project.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActionTrackerService } from 'src/app/shared/services/action-tracker.service';
import { VendorDataService } from 'src/app/shared/services/vendor-data.service';
@Component({
  selector: 'app-vendor-list-new',
  templateUrl: './vendor-list-new.component.html',
  styleUrls: ['./vendor-list-new.component.scss'],
  providers: [DatePipe]
})
export class VendorListNewComponent implements OnInit {

  dataSource !: MatTableDataSource<any>;
  editData: any;
  showForm: boolean = false;
  showLocation: boolean = false;
  vendorList: any;
  vendorListNew: any;
  dataSource1: any[] = [];

  patchResponse: any;
  newVendorItems: any;
  selectedVendorItems: any[] = [];
  selectedVendorItemsInitial: any[] = [];

  // staticText: any = (textConfiguration as any).default;
  projectId: any;
  projectdetail: any;
  opportunity_data: any;
  ownerdetails: any;
  details: any;
  opportunityFilteredOptions: any;
  vendorFilteredOptions: any;
  projectForm: FormGroup | any;
  myForm: FormGroup | any;
  getData: any;
  gdpList: any;
  vendorDetail: any;
  vendorId: any;
  selected: any;
  my_object: any;
  updateId: any;
  selectedOpportunity: any = '';
  statusList: any = [];
  /* @Input() selected_id: any; */
  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = "mapChart";
  chartData = [{ code3: "ABW", z: 105 }, { code3: "AFG", z: 35530 }];
  disabled: boolean = true;
  @ViewChild(MatPaginator) paginator !: MatPaginator;
  @ViewChild(MatSort) sort !: MatSort;

  constructor(private projectservice: ProjectService,
    private actionTrackerService: ActionTrackerService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private program: ProgramService,
    private fb: FormBuilder,
    private date: DatePipe,
    private toastr: ToastrService,
    private vendorData: VendorDataService,
    private ps: ProgramService,
    private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit(): void {

    //this.getVendor();
    this.vendorId = localStorage.getItem('vendorId');
    this.vendorList = localStorage.getItem('vendorList');
    console.log('vendorListAftersession', this.vendorList);
    this.vendorList = JSON.parse(localStorage.getItem('vendorList') || '{}');

    console.log('vendorId from session', this.vendorId);
    this.getvendorData();
    //this.getGDP();
    //this.getvendorDetail();
    // this.createForm();
    // this.getprojectId();
    this.showForm = false;
    this.showLocation = false
  }
  

  getvendorData() {
    this.spinner.show();
    this.vendorData.getVendorData().subscribe((res: any) => {
      this.getData = res;
      console.log('vendordata -', this.getData)
      this.dataSource = new MatTableDataSource(res);
      /***hardcoded for now*/
      this.dataSource1 = res;
      if (this.dataSource1.length > 4) {
        this.dataSource1.length = 4
      };
      console.log('dataSource list -', this.dataSource);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;


      this.spinner.hide();
    })
  }
  updateVendorDetails() {
    // if (this.projectdetail?.status?.slug != this.projectForm.value.selectedStatus?.slug) {
    //   this.updateProjectStatus();
    // }
    // if (this.projectForm.invalid)
    //   return;
    this.spinner.show();
    let payload = {

      "max_onsite_rate": this.myForm.value.OnsiteMax,
      "min_onsite_rate": this.myForm.value.OnsiteMin,
      "max_offshore_rate": this.myForm.value.OffShorMax,
      "min_offshore_rate": this.myForm.value.OffShorMin,
      "max_domestic_rate": this.myForm.value.DomMax,
      "min_domestic_rate": this.myForm.value.DomMin,
      "max_nearshore_rate": this.myForm.value.NearShorMax,
      "min_nearshore_rate": this.myForm.value.NearShorMin,

    }
    this.vendorData.updateMarketData(payload, this.updateId).subscribe((response: any) => {
      this.spinner.hide()
      if (response) {
        this.toastr.success("Data update successfully");
        // this.projectservice.getprojectDataById(this.projectId).subscribe();
      }
    }, err => this.spinner.hide());
  }

  getVendor() {
    this.ps.getOpportunityFormData('opportunity_empanelled_vendors').subscribe((res: any) => {
      this.vendorList = JSON.parse(JSON.stringify(res.records[0].field_values));
      console.log('vendorList......', this.vendorList);
      this.selected = this.vendorList[0];
      console.log('selected', this.selected);
      // this.newVendorItems = JSON.stringify(this.vendorList);
      // console.log('newVendorItems', this.newVendorItems);
      // if (this.patchResponse.opportunity_vendors?.length >= 0) {
      //   this.patchResponse.opportunity_vendors.forEach((element: any) => {
      //     this.vendorList = this.vendorList?.filter((e: any) => e?.id !== element.vendor_details?.id);
      //     this.vendorListNew = this.vendorList.filter((e: any) => e.id !== element.vendor);
      //     console.log('vendorListNew', this.vendorListNew);
      //     this.selectedVendorItems = this.selectedVendorItems.filter((e: any) => e?.id !== element.vendor_details?.id);
      //     this.selectedVendorItems.push(element.vendor_details)
      //   });
      // }
      this.selectedVendorItemsInitial = JSON.parse(JSON.stringify(this.selectedVendorItems));
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  // getGDP() {
  //   this.actionTrackerService.getVendorList().subscribe((res: any) => {
  //     if (res) {
  //       this.gdpList = res.records[0].field_values;
  //       console.log('gdpList', this.gdpList);
  //     }
  //   });
  // }

  // getvendorDetail() {
  //   this.actionTrackerService.getvendordetails().subscribe((response: any) => {
  //     this.vendorDetail = response;
  //     console.log('vendorDetail', this.vendorDetail);
  //   });
  // }


  showBtnLocation() {
    this.showLocation = true;

  }
  createForm() {
    this.myForm = this.fb.group({
      JobTitle: ['', Validators.required],

      OnsiteMin: ['', Validators.required],
      OnsiteMax: ['', Validators.required],

      DomMin: ['', Validators.required],
      DomMax: ['', Validators.required],

      OffShorMin: ['', Validators.required],
      OffShorMax: ['', Validators.required],

      NearShorMin: ['', Validators.required],
      NearShorMax: ['', Validators.required],


    });
    console.log(this.myForm.value)

  }
  editForm(editData: any) {
    this.editData = editData
    this.showForm = true;
    this.myForm = this.fb.group({
      JobTitle: ['', Validators.required],

      Onsite: ['', Validators.required],
      OnsiteMin: ['', Validators.required],
      OnsiteMax: ['', Validators.required],

      Dom: ['', Validators.required],
      DomMin: ['', Validators.required],
      DomMax: ['', Validators.required],

      OffShor: ['', Validators.required],
      OffShorMin: ['', Validators.required],
      OffShorMax: ['', Validators.required],

      NearShor: ['', Validators.required],
      NearShorMin: ['', Validators.required],
      NearShorMax: ['', Validators.required],
    });

    console.log('editData', this.editData);
    console.log('JobTitle', this.editData.JobTitle);
    console.log('updateId', this.editData.id);


    if (this.editData) {
      this.myForm.controls['JobTitle'].setValue(this.editData.job_title.name);
      this.myForm.controls['OnsiteMin'].setValue(this.editData.min_onsite_rate);
      this.myForm.controls['OnsiteMax'].setValue(this.editData.max_offshore_rate);
      this.myForm.controls['DomMin'].setValue(this.editData.min_domestic_rate);
      this.myForm.controls['DomMax'].setValue(this.editData.max_domestic_rate);
      this.myForm.controls['OffShorMin'].setValue(this.editData.min_offshore_rate);
      this.myForm.controls['OffShorMax'].setValue(this.editData.max_offshore_rate);
      this.myForm.controls['NearShorMin'].setValue(this.editData.min_nearshore_rate);
      this.myForm.controls['NearShorMax'].setValue(this.editData.max_nearshore_rate);
      this.updateId = this.editData.id


    }
  }
  submitForm() {
    console.log(this.myForm.value);
  }
  close() {
    this.showForm = false;
  }


}
