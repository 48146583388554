<!-- <div>
    <div class="modal-dialog m-0 logout-width">
        <div class="modal-content">
            <div class="modal-header">
                <h2 mat-dialog-title class="mb-0">ttttttt</h2>
            </div>
            <div class="modal-body">
                <h5 class="py-3 text-center primary m-0">ttttttttttt</h5>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-md-6">
                        <button mat-flat-button class="w-100 border bg-light">yuyuyuyy
                        </button>
                    </div>
                    <div class="col-md-6">
                        <button mat-flat-button class="w-100" color="primary">tttttttt
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div>
    <div class="modal-dialog m-0 custom-width">
        <div class="modal-content" style="border: 0px;">
            <app-modal-header-common [title]="'New Vendor'"> </app-modal-header-common>

            <div class="modal-body modal-oppz field-adjust">
                <form [formGroup]="vendorForm">
                    <div class="mb-2">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Vendor Name</mat-label>
                            <input type="text" matInput fullWidth formControlName="vendorName" id="vendorName">
                            <!-- <mat-error *ngIf="(submitted|| f.name.touched) && f.name.errors">
                                <small class="text-danger" *ngIf="f.name.errors.required">{{data.disName}} is
                                    required</small>
                            </mat-error> -->
                        </mat-form-field>
                    </div>
                    <div class="mb-2">
                        <label for="file">Logo</label>
                        <input formControlName="logo" id="file" type="file" class="form-control"
                            (change)="onFileChange($event)">
                        <!-- <div *ngIf="f['file'].touched && f['file'].invalid" class="alert alert-danger">
                            <div *ngIf="f['file'].errors && f['file'].errors['required']">File is required.</div>
                        </div> -->
                        <img [src]="imageSrc" *ngIf="imageSrc" style="height: 100px; width:100px">

                    </div>
                    <div class="mb-2">
                        <mat-form-field appearance="outline" class="w-100" required>
                            <mat-label>Vendor Type</mat-label>
                            <input type="text" matInput fullWidth formControlName="vendorType" id="vendorType">
                            <!-- <mat-error *ngIf="(submitted|| f.name.touched) && f.name.errors">
                                <small class="text-danger" *ngIf="f.name.errors.required">{{data.disName}} is
                                    required</small>
                            </mat-error> -->
                        </mat-form-field>
                    </div>



                    <div class="mb-2">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Vendor Email</mat-label>
                            <input type="text" matInput fullWidth formControlName="vendorEmail" id="vendorEmail">
                            <!-- <mat-error *ngIf="(submitted|| f.name.touched) && f.name.errors">
                                <small class="text-danger" *ngIf="f.name.errors.required">{{data.disName}} is
                                    required</small>
                            </mat-error> -->
                        </mat-form-field>
                    </div>

                    <div class="mb-2">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Website</mat-label>
                            <input type="text" matInput fullWidth formControlName="vendorWebsite" id="Website">
                            <!-- <mat-error *ngIf="(submitted|| f.name.touched) && f.name.errors">
                                <small class="text-danger" *ngIf="f.name.errors.required">{{data.disName}} is
                                    required</small>
                            </mat-error> -->
                        </mat-form-field>
                    </div>
                    <!-- <div class="mb-2">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Vendor Location</mat-label>
                            <input type="text" matInput fullWidth formControlName="vendorlocation" id="vendorlocation">
                          
                    </div> -->
                    <div class="mb-2">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Location</mat-label>
                            <mat-select formControlName="vendorlocation" [(value)]="selected"
                                placeholder="Select Vendor" required="true">

                                <mat-option *ngFor="let item of vendorLoc; let i=index" [value]="item.id">
                                    {{item.id}}
                                </mat-option> </mat-select>
                        </mat-form-field>
                        <!-- {{selected}} -->
                    </div>
                    <div class="mb-2">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Contract</mat-label>
                            <input type="text" matInput fullWidth formControlName="contract" id="Contract">
                            <!-- <mat-error *ngIf="(submitted|| f.name.touched) && f.name.errors">
                                <small class="text-danger" *ngIf="f.name.errors.required">{{data.disName}} is
                                    required</small>
                            </mat-error> -->
                        </mat-form-field>
                    </div>
                    <div class="mb-2">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Team</mat-label>
                            <input type="text" matInput fullWidth formControlName="team" id="Team">
                            <!-- <mat-error *ngIf="(submitted|| f.name.touched) && f.name.errors">
                                <small class="text-danger" *ngIf="f.name.errors.required">{{data.disName}} is
                                    required</small>
                            </mat-error> -->
                        </mat-form-field>
                    </div>

                </form>
            </div>
            <div class="modal-footer">
                <div class="d-flex justify-content-end">
                    <!-- <button mat-button mat-flat-button class="me-3 bg-light border" style="color: #4589d8">Close
                    </button> -->
                    <button mat-raised-button class="me-3 bg-light border" color="warn" mat-dialog-close
                        style="color: #4589d8">Close</button>

                    <button *ngIf="actionBtnAdd" status="primary" [disabled]="vendorForm.invalid" mat-button mat-flat-button (click)="addVendor()"
                        class="next-color text-white">Create
                        Vendor
                    </button>
                    <button *ngIf="actionBtnUpdate" status="primary" [disabled]="vendorForm.invalid" mat-button mat-flat-button (click)="updateVendor()"
                        class="next-color text-white">Update
                        Vendor
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>