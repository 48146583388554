import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VendorListNewComponent } from './vendor-list-new/vendor-list-new.component';
import { IndexComponent } from './index/index.component';
import { AddComponent } from './add/add.component';

const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    children: [
      {
        path: 'list',
        component: AddComponent,
      // }, {
      //   path: ':pageview/:id',
      //   component: ViewComponent,
      }, 
      // {
      //   path: '',
      //   redirectTo: 'dashboard',
      //   pathMatch: 'full'
      // },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VendorDataRoutingModule { }
